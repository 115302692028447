form {
  font-size: $base-font-size;

  .input {
    width: 100%;
  }

  .input--text {
    --accent-color: hsl(0, 0%, 71%);

    padding: 1rem 0;
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 1px solid var(--accent-color);

    &::placeholder {
      color: var(--accent-color);
    }
  }

  select {
    width: 100%;
    height: rem(48px);
    padding: map-get($careers-layout-spacing, 'sm')
      map-get($careers-layout-spacing, 'base');
    color: var(--color-gray-semidark);
    border-color: var(--color-gray-primary);
  }

  span.required-asterisk {
    color: var(--color-brand-red-ribbon);
  }

  // Bootstrap overrides

  input.form-control {
    border-color: var(--color-gray-primary);
    border-radius: 0;
  }

  .custom-control-input {
    width: rem(24px);
    height: rem(28px);
  }

  .custom-control.custom-checkbox {
    margin-bottom: rem(12px);
    min-height: rem(28px);
  }

  .custom-checkbox .custom-control-label {
    padding-top: rem(4px);
    padding-left: rem(8px);
    margin-bottom: 0;

    &::before,
    &::after {
      width: rem(24px);
      height: rem(24px);
    }

    &::before {
      border-color: var(--color-brand-red-ribbon);
      border-radius: 0;
    }
  }

  &.was-validated {
    .form-control:invalid {
      border-color: var(--color-brand-red-ribbon);
    }
  
    .custom-control-input:invalid~.custom-control-label {
      color: var(--color-brand-red-ribbon);
    }
  }

  .invalid-feedback {
    color: var(--color-brand-red-ribbon);
  }
}
