/* Trimmed down version of Bootstrap with only what we require  */

/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "mixins";
@import "utilities";
@import "print";

// Components

@import "carousel";
@import "nav";
@import "navbar";

// Re-enable if needed

// @import "root";
// @import "reboot";
// @import "type";
// @import "images";
// @import "code";
// @import "grid";
// @import "tables";
@import "forms";
@import "custom-forms";
// @import "buttons";
// @import "dropdown";
// @import "button-group";
// @import "input-group";
// @import "custom-forms";
// @import "card";
// @import "breadcrumb";
// @import "pagination";
// @import "badge";
// @import "jumbotron";
// @import "alert";
// @import "progress";
// @import "media";
// @import "list-group";
// @import "close";
@import "toasts";
// @import "modal";
// @import "tooltip";
// @import "popover";
// @import "spinners";
// @import "transitions";
