.hero {
  background-size: cover;
  background-position: center;
  position: relative;
  height: rem(772px);

  @include media-breakpoint-up('md') {
    height: rem(836px);
  }

  &::after {
    content: '';
    background-color: $black;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
  }

  &__information {
    position: absolute;
    z-index: 1;
    max-width: rem(900px);
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 0 map-get($careers-layout-spacing, 'lg');

    a {
      margin-top: map-get($careers-layout-spacing, 'lg');

      @include media-breakpoint-up('md') {
        margin-top: map-get($careers-layout-spacing, 'xl');
      }
    }

    h1 {
      font-size: rem(48px);
      line-height: map-get($careers-heading-size-desktop, 'h1');
      color: $white;
      margin-bottom: 0;

      @include media-breakpoint-up('md') {
        font-size: rem(80px);
        line-height: 1;
      }
    }
  }
}
