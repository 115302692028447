.open-position-explorer {
  &__filters {
    display: flex;
    flex-direction: column;
    gap: map-get($careers-layout-spacing, 'base');
    width: 100%;
    padding: map-get($careers-layout-spacing, 'base') 0;

    @include media-breakpoint-up('lg') {
      flex-direction: row;
      justify-content: center;
      padding: 0;
    }
  }

  &__select-wrapper {
    position: relative;
    width: 100%;
    
    @include media-breakpoint-up('lg') {
      max-width: rem(360px);
    }
  }

  &__select-caret {
    position: absolute;
    top: 50%;
    right: rem(20px);
    transform: translateY(-50%);
    width: rem(18px);
    height: rem(10px);
    pointer-events: none;
  }

  &__department-filter,
  &__location-filter {
    width: 100%;
    height: map-get($careers-layout-spacing, 'xl');
    padding: map-get($careers-layout-spacing, 'sm')
      map-get($careers-layout-spacing, 'base');
    color: var(--color-brand-santas-gray);
    appearance: none;
    padding-right: rem(40px);
    border-radius: 0;
  }

  &__headings {
    display: none;
    width: 100%;
    padding: map-get($careers-layout-spacing, 'md');
    font-size: map-get($font-sizes, 'default');
    color: var(--color-brand-santas-gray);
    text-align: left;

    @include media-breakpoint-up('lg') {
      display: flex;
      align-items: center;
      gap: map-get($careers-layout-spacing, 'base');
    }
  }

  &__headings-title {
    flex-basis: 60%;
  }

  &__headings-meta {
    display: flex;
    flex-grow: 1;

    > div {
      flex-basis: 50%;
    }
  }
}
