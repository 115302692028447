.open-position-detail__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .subpage-heading__title {
    margin-bottom: 16px;
  }

  .btn {
    width: initial;
    margin-top: 24px;
  }
}

.open-position-detail__content {
  display: flex;
  flex-direction: column;
  gap: map-get($careers-layout-spacing, 'xl');
  font-size: map-get($font-sizes, 'default');

  ul {
    padding-left: map-get($careers-layout-spacing, 'md');
  }

  li {
    margin-bottom: map-get($careers-layout-spacing, 'xs');

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

h2.open-position-detail__content-group-heading {
  font-size: map-get($careers-heading-size-mobile, 'h4');
  margin-bottom: map-get($careers-layout-spacing, 'base');

  @include media-breakpoint-up('lg') {
    font-size: map-get($careers-heading-size-desktop, 'h4');
  }
}

.open-position-detail__description {
  display: flex;
  flex-direction: column;
  gap: map-get($careers-layout-spacing, 'xl');

  h2 {
    margin-bottom: map-get($careers-layout-spacing, 'base');
  }

  ul {
    padding-left: map-get($careers-layout-spacing, 'lg');
  }

  ul,
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.open-position-detail__meta {
  display: flex;
  flex-wrap: wrap;
  column-gap: map-get($careers-layout-spacing, 'base');
  row-gap: map-get($careers-layout-spacing, 'xs');
  font-size: map-get($font-sizes, 'default');
  color: var(--color-brand-santas-gray);

  &-item {
    padding-right: map-get($careers-layout-spacing, 'base');
    border-right: 1px solid var(--color-brand-ghost);

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }
}

.open-position-detail__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: map-get($careers-layout-spacing, 'xl');
  padding-top: map-get($careers-layout-spacing, 'xl');

  @include media-breakpoint-up('lg') {
    grid-template-columns: 2fr 1fr;
  }

  &-item {
    display: flex;
    flex-direction: column;
    gap: map-get($careers-layout-spacing, 'xl');
  }
}
