@font-face {
  font-family: 'Neuzeit S';
  font-weight: 500;
  src: url('/assets/fonts/NeuzeitS-Book.ttf'),
       url('/assets/fonts/NeuzeitS-Book.woff2') format('woff2'),
       url('/assets/fonts/NeuzeitS-Book.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Neuzeit S';
  font-weight: bold;
  src: url('/assets/fonts/NeuzeitS-BookHeavy.ttf'),
       url('/assets/fonts/NeuzeitS-BookHeavy.woff2') format('woff2'),
       url('/assets/fonts/NeuzeitS-BookHeavy.woff') format('woff');
  font-display: swap;
}
