// Apply a nicer and animated underline
@mixin animated-underline() {
  position: relative;
  white-space: normal;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;

    width: 100%;
    height: 1px;

    border: 1px solid currentColor;
    opacity: 0;
    transition: opacity $base-animation-duration ease-in-out;
  }

  &:hover:after {
    opacity: 1;
  }
}

@mixin multiple-line-underline() {
  background-image: linear-gradient(to bottom, transparent 0%, transparent 100%);
  background-repeat: no-repeat;
  background-size: 100% 2px;
  background-position: bottom;

  padding-bottom: 3px;

  &:hover {
    background-image: linear-gradient(to bottom, currentColor 0%, currentColor 100%);
  }

  &:after {
    content: initial;
  }
}
