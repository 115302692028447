.navbar-scrollable {
  margin: 0;
  width: 100%;
  top: 0;
  max-width: 100vw;
  -webkit-mask-image: linear-gradient(
    to left,
    transparent,
    #000 1rem
  );

  &__list {
    height: 100%;
    margin: 0 auto;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
  }

  ul {
    list-style: none;
    display: flex;
    gap: map-get($careers-layout-spacing, 'lg');
    width: max-content;
    padding: 0;
    padding-inline-end: map-get($careers-layout-spacing, 'base');
    margin-bottom: 0;

    @include media-breakpoint-up('md') {
      padding-inline-end: 0;
    }
  }

  .nav-link {
    font-size: map-get($font-sizes, 'xl');
    color: map-get($color-brand, 'santas-gray');
    padding: map-get($careers-layout-spacing, 'md') 0;

    &:hover,
    &.active {
      color: map-get($color-brand, 'red-ribbon');
    }

    &::after {
      bottom: 0;
      width: 100%;
    }

    &:hover::after {
      opacity: 0;
    }

    &.active {
      font-weight: map-get($font-weight, 'bolder');

      &::after{
        opacity: 1;
      }
    }
  }
}
