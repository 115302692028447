.feature-list {
  // Specific value for this component
  $item-padding-y: rem(40px);
  $item-width-mobile: rem(300px);

  &__title {
    margin-bottom: map-get($careers-layout-spacing, 'lg');
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up('lg') {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: map-get($careers-layout-spacing, 'md');
    width: $item-width-mobile;
    padding: $item-padding-y 0;

    @include media-breakpoint-up('lg') {
      padding: $item-padding-y map-get($careers-layout-spacing, 'md');
    }
  }

  &__item-title {
    font-size: map-get($font-sizes, 'xl');
    margin-bottom: map-get($careers-layout-spacing, 'xs');

    @include media-breakpoint-up('lg') {
      font-size: map-get($font-sizes, 'xxl');
    }
  }

  &__item-content {
    font-size: map-get($font-sizes, 'default');
  }
}

// Column Modifiers

.feature-list__list {
  @for $i from 2 through 5 {
    &.feature-list__list--columns-#{$i} {
      @include media-breakpoint-up('lg') {
        .feature-list__item {
          flex-basis: calc(100% / #{$i});
        }
      }
    }
  }
}
