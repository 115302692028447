.impact-cta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: map-get($careers-layout-spacing, 'md');
  padding: map-get($careers-layout-spacing, 'md');

  @include linear-gradient-background(
    var(--color-gray-light),
    var(--color-gray-lightest)
  );

  @include media-breakpoint-up('lg') {
    padding: map-get($careers-layout-spacing, 'xl');
  }

  h2.impact-cta__title {
    font-size: map-get($careers-heading-size-mobile, 'h4');
    margin-bottom: 0;

    @include media-breakpoint-up('lg') {
      font-size: map-get($careers-heading-size-desktop, 'h4');
    }
  }

  &__description {
    font-size: map-get($font-sizes, 'default');
    color: var(--color-gray-semidark);
  }

  .btn {
    width: auto;
    margin-top: 0;
  }
}
