.layout-careers .container {
  width: 100%;
  padding: 0 map-get($careers-layout-spacing, 'lg');
  margin-right: auto;
  margin-left: auto;
  max-width: calc(1440px + #{$grid-gutter-width * 2});

  @include media-breakpoint-up('xl') {
    padding: 0 map-get($careers-layout-spacing, 'xxxl');
  }
}

.layout-careers .app-header {
  position: fixed;
  top: 0;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 map-get($careers-layout-spacing, 'md');
  z-index: map-get($zIndex, 'highest');
  min-height: $careers-header-height;

  @include media-breakpoint-up('xl') {
    padding: 0 map-get($careers-layout-spacing, 'xxxl');
    min-height: $careers-header-height-md;
  }
}

.layout-careers .app-content {
  min-height: 100vh;
  margin-top: $careers-header-height;

  @include media-breakpoint-up('xl') {
    margin-top: $careers-header-height-md;
  }
}

.layout-careers .content-section {
  padding: map-get($careers-layout-spacing, 'xxxl') 0;

  @include media-breakpoint-up('lg') {
    padding: map-get($careers-layout-spacing, 'xxxl') * 2 0;
  }

  &.content-section--no-padding {
    padding: 0;
  }

  &.content-section--thin {
    padding-top: map-get($careers-layout-spacing, 'lg');
    padding-bottom: map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('lg') {
      padding-top: map-get($careers-layout-spacing, 'xxxl');
      padding-bottom: map-get($careers-layout-spacing, 'xxxl');
    }
  }

  &.content-section--thick {
    padding-top: map-get($careers-layout-spacing, 'xxxl') * 2;
    padding-bottom: map-get($careers-layout-spacing, 'xxxl') * 2;
  }

  // Special cases

  &.our-teams {
    padding-bottom: 0;
  }

  &.open-position-list {
    .open-position-list__title {
      margin-bottom: 0;
    }
  }

  &.open-position-explorer,
  &.open-position-detail,
  &.open-position-apply {
    padding-top: 0;
  }

  &.subpage-heading {
    padding: map-get($careers-layout-spacing, 'xxxl') 0;
  }

  &.carousel-image {
    padding-top: map-get($careers-layout-spacing, 'xxxl');
    padding-bottom: map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('lg') {
      padding-top: map-get($careers-layout-spacing, 'xxxl');
      padding-bottom: 0;
    }
  }

  &.information-section {
    @include media-breakpoint-up('lg') {
      padding: map-get($careers-layout-spacing, 'xxxl') 0;
    }
  }
}

.layout-careers .app-footer {
  padding: map-get($careers-layout-spacing, 'md');

  @include media-breakpoint-up('md') {
    padding: map-get($careers-layout-spacing, 'md')
      map-get($careers-layout-spacing, 'xxxl');
  }
}
