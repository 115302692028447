.simple-testimonial {
  blockquote {
    margin-bottom: map-get($careers-layout-spacing, 'md');
  }

  figure,
  figcaption {
    margin-bottom: 0;
  }

  &__heading {
    font-size: map-get($font-sizes, 'xxl');
    margin-bottom: map-get($careers-layout-spacing, 'md');
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: map-get($careers-layout-spacing, 'base');
    padding:  map-get($careers-layout-spacing, 'xl')
      map-get($careers-layout-spacing, 'md');
    font-size: map-get($font-sizes, 'default');

    @include linear-gradient-background(
      var(--color-gray-light),
      var(--color-gray-lightest)
    );

    @include media-breakpoint-up('lg') {
      padding: map-get($careers-layout-spacing, 'xl');
    }
  }

  &__author {
    display: flex;
    align-items: center;
    gap: map-get($careers-layout-spacing, 'base');
  }

  &__author-photo {
    @include employee-headshots(
      rem(64px),
      rem(64px),
      rem(100px),
      rem(100px)
    );
  }

  &__author-name {
    font-size: map-get($careers-heading-size-mobile, 'h5');
    font-weight: map-get($font-weight, 'bolder');
    margin-bottom: 0;

    @include media-breakpoint-up('lg') {
      font-size: map-get($font-sizes, 'lg');
    }
  }

  &__author-position {
    font-size: map-get($font-sizes, 'default');
    color: var(--color-gray-semidark);

    @include media-breakpoint-up('lg') {
      font-size: map-get($font-sizes, 'lg');
    }
  }
}
