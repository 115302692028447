.icon-decoration {
  position: absolute;
  width: rem(130px);
  height: rem(130px);
  max-width: 100%;
  max-height: 100%;

  @include media-breakpoint-up('xl') {
    width: rem(180px);
    height: rem(180px);
  }
}

// Position Modifiers

.icon-decoration.icon-decoration {
  &--top-right {
    top: 0;
    right: 0;
  }

  &--top-left {
    top: 0;
    left: 0;
  }

  &--bottom-right {
    right: 0;
    bottom: 0;
  }

  &--bottom-left {
    bottom: 0;
    left: 0;
  }
}


// Sizing Modifiers

.icon-decoration.icon-decoration {
  &--2x {
    width: calc(#{rem(130px)} * 2);
    height: calc(#{rem(130px)} * 2);

    @include media-breakpoint-up('xl') {
      width: calc(#{rem(180px)} * 2);
      height: calc(#{rem(180px)} * 2);
    }
  }

  &--expansive {
    width: auto;
    height: 80%;
  }
}
