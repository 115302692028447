.text-cta {
  position: relative;

  &--default {
    background-color: $white;
  }
}

.text-cta__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: rem(950px);

  .btn {
    width: auto;
    margin-top: map-get($careers-layout-spacing, 'md');

    @include media-breakpoint-up('lg') {
      margin-top: map-get($careers-layout-spacing, 'lg');
    }
  }
}

.text-cta__heading {
  &--large {
    @include media-breakpoint-up('lg') {
      font-size: map-get($careers-heading-size-desktop, 'h1');
    }
  }
}

.text-cta__subtitle {
  font-size: map-get($font-sizes, 'sm');
  font-weight: map-get($font-weight, 'bolder');
  text-transform: uppercase;
}

.text-cta__icon {
  position: absolute;
  width: auto;
  height: 100%;
  max-height: rem(260px);

  @include media-breakpoint-up('md') {
    max-height: rem(360px);
  }

  &--top-right {
    top: 0;
    right: 0;
  }

  &--top-left {
    top: 0;
    left: 0;
  }

  &--bottom-right {
    right: 0;
    bottom: 0;
  }

  &--desktop-full {
    @include media-breakpoint-up('lg') {
      max-height: unset;
      height: 100%;
    }
  }
}
