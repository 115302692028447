.open-position-apply__form {
  display: flex;
  flex-direction: column;
  gap: map-get($careers-layout-spacing, 'md');
  max-width: map-get($container-max-widths, 'lg');
  margin: 0 auto;

  @include media-breakpoint-up('lg') {
    gap: map-get($careers-layout-spacing, 'xl');
  }

  fieldset {
    display: flex;
    flex-direction: column;
    padding-bottom: map-get($careers-layout-spacing, 'md');
    border-bottom: 1px solid var(--color-gray-primary);

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }

    @include media-breakpoint-up('lg') {
      padding-bottom: map-get($careers-layout-spacing, 'xl');
    }
  }

  label {
    font-weight: map-get($font-weight, 'bolder');
    margin-bottom: map-get($careers-layout-spacing, 'xs');
  }

  .custom-checkbox {
    label {
      font-weight: map-get($font-weight, 'normal');
    }
  }

  .form-group {
    margin-bottom: 0;
  }
}

.open-position-apply__legend {
  font-size: map-get($font-sizes, 'default');
  font-weight: map-get($font-weight, 'bolder');
  margin-bottom: map-get($careers-layout-spacing, 'base');

  &--large {
    font-size: map-get($font-sizes, 'xl');
  }

  &--required {
    color: var(--color-brand-red-ribbon);
  }

  &:has(~ .open-position-apply__fieldset-description) {
    margin-bottom: map-get($careers-layout-spacing, 'xxs');
  }
}

.open-position-apply__fieldset-description {
  color: var(--color-gray-semidark);
}

.open-position-apply__submit {
  text-align: center;

  .btn {
    margin-top: 0;

    @include media-breakpoint-up('lg') {
      width: map-get($careers-layout-spacing, 'xxxl') * 4;
    }
  }
}

.open-position-apply__success {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: map-get($careers-layout-spacing, 'md');

  &-title {
    margin-bottom: map-get($careers-layout-spacing, 'xs');
  }

  &-text {
    max-width: 65ch;
  }
}

.open-position-apply__field-group {
  display: flex;
  flex-direction: column;
  gap: map-get($careers-layout-spacing, 'md');
}
