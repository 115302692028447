.app-header__logo {
  width: rem(99px);
  line-height: 1;
  padding: 0;
  margin: 0;

  @include media-breakpoint-up('xl') {
    width: rem(132px);
  }
}

.app-header__menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: map-get($careers-layout-spacing, 'base');
  width: 100%;
  padding: map-get($careers-layout-spacing, 'xl') 0;

  @include media-breakpoint-up('xl') {
    flex-direction: row;
    gap: map-get($careers-layout-spacing, 'xl');
    width: auto;
    margin: 0;
    padding: 0;
  }

  &-item {
    width: rem(246px);
    padding-bottom: map-get($careers-layout-spacing, 'base');
    border-bottom: 1px solid var(--color-gray-primary);
    text-align: center;

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    @include media-breakpoint-up('xl') {
      width: auto;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &-link {
    font-size: map-get($font-sizes, 'default');
    font-weight: map-get($font-weight, 'bolder');
    color: var(--color-gray-semidark);

    &:hover {
      color: var(--color-gray-semidark);
    }
  }
}

.app-header__menu-subpages {
  position: relative;

  @include media-breakpoint-up('xl') {
    display: inline-block;

    &:hover, &:focus-within {
      .app-header__menu-dropdown-content {
        display: flex;
        flex-direction: column;
        gap: map-get($careers-layout-spacing, 'base');
      }
    }
  }

  .icon {
    display: none;

    @include media-breakpoint-up('xl') {
      display: initial;
    }
  }

  .app-header__menu-link {
    @include media-breakpoint-up('xl') {
      display: flex;
      align-items: center;
      gap: map-get($careers-layout-spacing, 'xs');
    }
  }

  .app-header__menu-dropdown-content {
    display: flex;
    flex-direction: column;
    gap: map-get($careers-layout-spacing, 'xs');
    padding-top: map-get($careers-layout-spacing, 'base');

    @include media-breakpoint-up('xl') {
      position: absolute;
      display: none;
      min-width: fit-content;
      padding: map-get($careers-layout-spacing, 'base') map-get($careers-layout-spacing, 'lg');
      z-index: map-get($zIndex, 'high');
      background-color: $white;
      box-shadow: $careers-navbar-box-shadow;
    }
  }
}
