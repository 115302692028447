.banner-heading {
  color: $white;
}

.banner-heading__content {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: rem(300px);

  @include image-overlay();

  @include media-breakpoint-up('md') {
    height: rem(460px);
  }

  @include media-breakpoint-up('lg') {
    height: rem(640px);
  }
}

.banner-heading__title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: {
    right: map-get($careers-layout-spacing, 'lg'); 
    bottom: map-get($careers-layout-spacing, 'lg');
    left: map-get($careers-layout-spacing, 'lg'); 
  }

  @include media-breakpoint-up('lg') {
    padding: {
      right: map-get($careers-layout-spacing, 'xxxl');
      bottom: map-get($careers-layout-spacing, 'xxxl');
      left: map-get($careers-layout-spacing, 'xxxl');
    }
  }

  h1 {
    font-size: map-get($heading-sizes, 'h4');
    font-weight: map-get($font-weight, 'bolder');
    max-width: rem(300px);
    margin-bottom: 0;

    @include media-breakpoint-up('md') {
      font-size: map-get($heading-sizes, 'h2');
      max-width: 100%;
    }
  }
}
