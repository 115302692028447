.btn {
  @include transition(background $base-animation-duration);

  --btn-padding-x: 2rem;
  --btn-padding-y: 0.875rem;
  --btn-border-width: 1px;

  display: inline-block;
  padding: var(--btn-padding-y) var(--btn-padding-x);

  position: relative;
  z-index: map-get($zIndex, 'default');

  line-height: $base-line-height;
  font-size: map-get($font-sizes, 'default');
  text-align: center;
  font-weight: bold;

  border: var(--btn-border-width) solid transparent;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;

  &:focus {
    outline: none
  }
}

// Theme modifiers

.btn.btn--primary {
  $accent-color: map-get($color-brand, 'red-ribbon');

  color: hsl(0, 0%, 100%);
  background: $accent-color;
  border-color: $accent-color;

  @include animated-button($accent-color);
}

.btn.btn--secondary {
  $accent-color: hsl(0, 0%, 100%);

  color: var(--color-brand-red-ribbon);
  background: $accent-color;
  border-color: $accent-color;

  @include animated-button($accent-color);
}

.btn.btn--outline {
  --accent-color: hsl(0, 0%, 100%);
  --border-width: 2px;
  --padding-y: calc(var(--btn-padding-y) - (var(--border-width) - var(--btn-border-width)));

  @include transition(box-shadow $base-animation-duration);

  padding: var(--padding-y) var(--btn-padding-x);
  color: var(--accent-color);

  background: transparent;
  border: var(--border-width) solid var(--accent-color);
  will-change: box-shadow;

  // Inset border shown on mouse hover

  &:before {
    @include transition(opacity $base-animation-duration);

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: map-get($zIndex, 'behind');

    width: 100%;
    height: 100%;

    opacity: 0;
    border: 1px solid var(--accent-color);
  }

  &:hover:before,
  &:focus:before {
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 10px 10px 0 hsla(0%,0%,0%,0.05);
  }

  &:focus {
    background: var(--accent-color);
    color: var(--color-brand-big-stone);
  }
}

.btn.btn--primary.btn--outline {
  $accent-color: map-get($color-brand, 'red-ribbon');

  color: $accent-color;
  border-color: $accent-color;

  &:before {
    background: $accent-color;
  }

  &:hover {
    color: hsl(0, 0%, 100%);
  }
}

.btn.btn--link {
  text-decoration: none;

  &:after {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.btn.btn--chromeless {
  padding: 0;
  background: none;
  border: none;
}

// Size modifiers

.btn.btn--large {
  @include media-breakpoint-up('lg') {
    --btn-padding-x: 5rem;
    --btn-padding-y: 1.125rem;

    font-size: map-get($font-sizes, 'lg');
  }
}

.btn.btn--primary.btn--large {
  &:hover:before {
    @include media-breakpoint-up('lg') {
      transform: scale(25);
    }
  }
}
