.content-gallery {
  &__copy {
    padding: map-get($careers-layout-spacing, 'xxxl') 0;
    display: flex;
    flex-direction: column;
    gap: map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('lg') {
      flex-direction: row;
      gap: map-get($careers-layout-spacing, 'xxxl');
    }
  }

  &__copy-title,
  &__copy-content {
    flex-basis: 50%;
  }

  &__copy-title {
    h2 {
      margin-bottom: 0;
      font-size: map-get($font-sizes, 'xxl');

      @include media-breakpoint-up('lg') {
        font-size: map-get($heading-sizes, 'h5');
      }
    }
  }

  &__copy-content {
    p {
      @include media-breakpoint-up('lg') {
        font-size: map-get($font-sizes, 'default');
      }
    }
  }

  &__images {
    @include media-breakpoint-up('lg') {
      display: flex;
      gap: map-get($careers-layout-spacing, 'xxxs');
      padding-top: map-get($careers-layout-spacing, 'xxxl');
    }
  }

  &__image {
    width: 100%;

    @include media-breakpoint-up('lg') {
      width: 50%;
    }
  }
}

// Displaying images on desktop

.content-gallery__images ~ .container .content-gallery__copy {
  @include media-breakpoint-up('lg') {
    padding-top: map-get($careers-layout-spacing, 'xl');
  }
}

// Text next to text (no images)

.content-gallery ~ .content-gallery:not(:has(.content-gallery__images)) .content-gallery__copy {
  padding-top: 0;

  @include media-breakpoint-up('lg') {
    padding-top: map-get($careers-layout-spacing, 'xxxl');
  }
}
