.hiring-process-infographic {
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('lg') {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: stretch;
      gap: map-get($careers-layout-spacing, 'base');
    }
  }

  &__item {
    text-align: center;
    width: 100%;

    @include media-breakpoint-up('lg') {
      width: rem(200px);
    }

    .icon {
      margin-bottom: map-get($careers-layout-spacing, 'lg');
    }
  }

  &__item-text {
    font-size: map-get($font-sizes, 'lg');
  }
}
