.information-section {
  &__content {
    text-align: center;
    max-width: rem(900px);
    margin: 0 auto;

    .icon {
      margin-bottom: map-get($careers-layout-spacing, 'md');
    }
  }

  &__description {
    font-size: map-get($font-sizes, 'xl');
    line-height: map-get($careers-heading-leading-mobile, 'h4');
  }
}
