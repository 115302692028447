// Colors

$color-gray: (
  primary: hsl(204, 15%, 87%),
  light: hsl(206, 28%, 95%),
  lighter: hsl(204, 45%, 98%),
  lightest: hsl(210, 50%, 98%),
  semidark: hsl(218, 20%, 32%),
  dark: hsl(258, 100%, 10%)
);

// Brand palette (using http://chir.ag/projects/name-that-color/)
$color-brand: (
  red-ribbon: hsl(344, 95%, 47%),
  pomegranate: hsl(10, 88%, 54%),
  lucky-point: hsl(240, 66%, 26%),
  electric-violet: hsl(263, 88%, 47%),
  purple-heart: hsl(253, 54%, 49%),
  big-stone: hsl(219, 46%, 15%),
  valhalla: hsl(253, 54%, 18%),
  woodsmoke : hsl(240, 11%, 9%),
  athens-gray: hsl(214, 16%, 92%),
  ghost: hsl(224, 10%, 79%),
  santas-gray: hsl(227, 8%, 65%),
  // Extra color based on `big-stone`.
  // In the design this is generated with q white overlay at 5% on `big-stone`
  cloud-burst: hsl(216, 37%, 19%)
);

$color-alert: (
  info: hsl(235, 96%, 63%)
);

// Layout

$grid-columns: 12;
$grid-gutter-width: 60px;

// Grid

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1380px
);

// Z-index
$zIndex: (
  default: 1,
  behind: -1,
  high: 5,
  higher: 100,
  highest: 1000,
  sky: 10000
);

// Typography

$font-sizes: (
  default: 1rem,  // 16px
  xxs: 0.6875rem, // 11px
  xs: 0.75rem,    // 12px
  sm: 0.875rem,   // 14px
  md: 0.9375rem,  // 15px
  lg: 1.0625rem,  // 17px
  xl: 1.25rem,    // 20px
  xxl: 1.5rem,    // 24px
  xxxl: 1.85rem,  // 29.6px
  xxxxl: 2rem     // 32px
);

$heading-sizes: (
  h1: 10.625rem,  // 170px
  h2: 4.125rem,   // 66px
  h3: 3.25rem,    // 52px
  h4: 2.75rem,    // 44px
  h5: 2.3125rem,  // 37px
  h6: 1rem        // 16px
);

$font-weight: (
  normal: 400,
  bold: 700,
  bolder: 800
);

$base-font-size: map-get($font-sizes, 'default');
$base-font-family: 'Neuzeit S', system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Lucida Grande",
  "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu",
  "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
$base-line-height: 1.5;
$base-font-color: map-get($color-gray, 'dark');

// Animations

$base-animation-duration: 300ms;
$base-animation-timing: linear;

// Bootstrap Configuration

// Carousel

$carousel-control-color: hsl(0, 0%, 100%);
$carousel-control-width: 2.625rem;
$carousel-control-opacity: 0.5;

$carousel-indicator-width: 20px;
$carousel-indicator-height: 20px;
$carousel-indicator-spacer: 0.5rem;
$carousel-indicator-active-bg: map-get($color-gray, 'dark');

$carousel-control-icon-width: 20px;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,%3Csvg width='11px' height='19px' viewBox='0 0 11 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg transform='translate(-16.000000, -19.000000)' fill='#{$carousel-control-color}'%3E%3Cg transform='translate(21.000000, 28.500000) scale(-1, 1) translate(-21.000000, -28.500000) translate(14.000000, 19.000000)'%3E%3Cg transform='translate(6.522727, 9.643939) scale(-1, -1) rotate(90.000000) translate(-6.522727, -9.643939) translate(-2.704545, 4.462121)'%3E%3Cpath d='M2.40168314,1.01327089 C1.98004927,0.608138801 1.27525109,0.588963324 0.8274735,0.970441259 C0.379695909,1.35191921 0.358503409,1.98959265 0.780135045,2.39472476 L8.64694473,9.9536503 C9.09390268,10.383117 9.85122218,10.3748706 10.2866273,9.93579626 L18.0447928,2.11226677 C18.4555286,1.69807122 18.4173799,1.06104396 17.959584,0.689426567 C17.5017882,0.317809171 16.797705,0.352325573 16.3869714,0.766521105 C13.2798648,3.89980554 10.9495349,6.24976886 9.39598166,7.81641108 L2.40168314,1.01327089 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
$carousel-control-next-icon-bg: url("data:image/svg+xml,%3Csvg width='11px' height='19px' viewBox='0 0 11 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg transform='translate(-15.000000, -19.000000)' fill='#{$carousel-control-color}'%3E%3Cg transform='translate(14.000000, 19.000000)'%3E%3Cg transform='translate(6.522727, 9.643939) scale(-1, -1) rotate(90.000000) translate(-6.522727, -9.643939) translate(-2.704545, 4.462121)'%3E%3Cpath d='M2.40168314,1.01327089 C1.98004927,0.608138801 1.27525109,0.588963324 0.8274735,0.970441259 C0.379695909,1.35191921 0.358503409,1.98959265 0.780135045,2.39472476 L8.64694473,9.9536503 C9.09390268,10.383117 9.85122218,10.3748706 10.2866273,9.93579626 L18.0447928,2.11226677 C18.4555286,1.69807122 18.4173799,1.06104396 17.959584,0.689426567 C17.5017882,0.317809171 16.797705,0.352325573 16.3869714,0.766521105 C13.2798648,3.89980554 10.9495349,6.24976886 9.39598166,7.81641108 L2.40168314,1.01327089 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

$carousel-transition: transform $base-animation-duration * 2 ease;

// Blog

// Layout
$blog-side-panel-width: 22.5rem; // 360px

// Layout - Header
$blog-header-height: 4rem; // 64px
$blog-header-height-md: 4.5rem; // 72px
$blog-header-divider-opacity: 0.32; // 32%

// Layout - Footer
$blog-footer-height: 35rem; // 560px
$blog-footer-height-md: 14.375rem; // 230px

// Article
$blog-article-container-max-width: 67.5rem; //1080px
$blog-article-column-gap-width: 5.5rem; // 88px
$blog-article-side-panel-width: $blog-side-panel-width;

// Card Article
$blog-card-article-headline-color: map-get($color-gray, 'dark');
$blog-card-article-description-color: map-get($color-brand, 'woodsmoke');
$blog-card-article-author-color: map-get($color-brand, 'red-ribbon');
$blog-card-article-timestamp-color: map-get($color-brand, 'santas-gray');
$blog-card-article-tag-item-color: map-get($color-brand, 'red-ribbon');
$blog-card-article-divider-color: map-get($color-brand, 'athens-gray');

// Category Archive
$blog-category-archive-heading-size: 3.375rem; // 54px

// Header
$blog-header-margin-top: 72px;
$blog-header-margin-bottom: 80px;

// Client logos
$client-logo-width-vw: (
  central-group: 23.6vw,
  cp-group: 6.47vw,
  ptt: 13.21vw,
  scg: 16.43vw,
  the-pizza-company: 9.72vw,
  1112d: 7.78vw,
  easyhotel: 19.94vw,
  mindvalley: 26.52vw
);

$client-logo-width-px: (
  central-group: 140px,
  cp-group: 45px,
  ptt: 73px,
  scg: 108.1px,
  the-pizza-company: 59.91px,
  1112d: 48px,
  easyhotel: 122.95px,
  mindvalley: 163.5px
);

// Careers

// Layout
$careers-layout-spacing: (
  xxxs: 0.125rem, // 2px
  xxs: 0.25rem, // 4px
  xs: 0.5rem,   // 8px
  sm: 0.75rem,  // 12px
  base: 1rem,   // 16px
  md: 1.5rem,   // 24px
  lg: 2rem,     // 32px
  xl: 3rem,     // 48px
  xxl: 3.25rem, // 52px
  xxxl: 4rem,   // 64px
  xxxxl: 8rem   // 128px
);

$careers-heading-size-desktop: (
  h1: 4rem,   // 64px
  h2: 3rem,   // 48px
  h3: 2rem,   // 32px
  h4: 1.5rem, // 24px
  h5: 1.25rem // 20px
);

$careers-heading-leading-desktop: (
  h1: 5rem,   // 80px
  h2: 4rem,   // 64px
  h3: 2.5rem, // 40px
  h4: 2rem,   // 32px
  h5: 1.75rem // 28px
);

$careers-heading-size-mobile: (
  h1: 3rem,     // 48px
  h2: 2rem,     // 32px
  h3: 1.5rem,   // 24px
  h4: 1.25rem,  // 20px
  h5: 1.125rem  // 18px
);

$careers-heading-leading-mobile: (
  h1: 4rem,    // 64px
  h2: 2.5rem,  // 40px
  h3: 2rem,    // 32px
  h4: 1.75rem, // 28px
  h5: 1.625rem // 26px
);

$careers-navbar-box-shadow: 0 0.125rem 1rem 0 rgba(0, 0, 0, 0.04);

$client-cases-bg-brand-colors: (
  jollibee: hsl(352, 100%, 43%),
  cbtl: hsl(262, 58%, 96%),
  the-1: hsl(120, 1%, 29%),
  easy-hotel: hsl(24, 100%, 67%),
  smash-burger: hsl(14, 100%, 50%),
  tpc: hsl(159, 100%, 26%),
);

// Layout - Header
$careers-header-height: 5rem;
$careers-header-height-md: 7rem;
