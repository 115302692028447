.location-office {
  &_title {
    margin-bottom: map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('lg') {
      margin-bottom: map-get($careers-layout-spacing, 'xxxl');
    }

    h2 {
      margin-bottom: 0;
    }
  }

  &_title.location-office_title--tabs {
    margin-bottom: map-get($careers-layout-spacing, 'base');

    @include media-breakpoint-up('lg') {
      margin-bottom: map-get($careers-layout-spacing, 'md');
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('lg') {
      flex-direction: row;
      align-items: stretch;
      gap: map-get($careers-layout-spacing, 'xxxl');
    }
  }

  &__map,
  &__features {
    flex-basis: 50%;
    text-align: left;
  }
}

// Google Maps

.location-office__map {
  display: flex;
  flex-direction: column;

  &-element {
    flex: 1;
    background-color: var(--color-brand-ghost);
    min-height: rem(340px);
  }

  // Static map

  .map {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--color-brand-ghost);
    cursor: pointer;
  }
}

// Features list

.location-office__features {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: map-get($careers-layout-spacing, 'lg');
  padding: 0 map-get($careers-layout-spacing, 'md');
  margin: 0;

  @include media-breakpoint-up('lg') {
    gap: unset;
    padding-left: 0;
    padding-right: map-get($careers-layout-spacing, 'xxxl');
  }
}

.location-office__feature {
  display: flex;
  flex-direction: column;
  gap: map-get($careers-layout-spacing, 'base');
  padding-bottom: map-get($careers-layout-spacing, 'md');
  border-bottom: 1px solid var(--color-brand-ghost);

  @include media-breakpoint-up('lg') {
    padding: map-get($careers-layout-spacing, 'lg') 0;
  }

  &-icon {
    flex-shrink: 0;
  }

  &-title {
    display: flex;
    align-items: center;
    gap: map-get($careers-layout-spacing, 'base');
    font-size: map-get($font-sizes, 'xl');
    font-weight: map-get($font-weight, 'bolder');

    @include media-breakpoint-up('lg') {
      font-size: map-get($font-sizes, 'xxl');
    }
  }

  &-text {
    margin-bottom: 0;
    padding-left: map-get($careers-layout-spacing, 'xxxl');

    p:last-of-type {
      margin-bottom: 0;
    }

    @include media-breakpoint-up('lg') {
      font-size: map-get($font-sizes, 'default');
    }
  }
}

// Address

.location-office__address {
  position: relative;
  color: $white;
  background-color: var(--color-brand-valhalla);
  padding: map-get($careers-layout-spacing, 'md');
  display: flex;
  flex-direction: column;
  gap: map-get($careers-layout-spacing, 'xs');

  &-title {
    margin-bottom: 0;
    font-size: map-get($font-sizes, 'xl');
    font-weight: map-get($font-weight, 'bolder');

    @include media-breakpoint-up('lg') {
      font-size: map-get($font-sizes, 'xxl');
    }
  }

  &-content {
    font-size: map-get($font-sizes, 'default');
  }

  &-link {
    display: flex;
    gap: map-get($careers-layout-spacing, 'xs');

    a {
      font-size: map-get($font-sizes, 'default');
      font-weight: map-get($font-weight, 'bolder');
      color: $white;
    }
  }

  .icon-decoration {
    right: map-get($careers-layout-spacing, 'md');
    width: auto;
    height: 100%;
  }
}

// CSS Only Tabs

.location-office__tabs {
  [type='radio'] {
    display: none;
  }

  &-list {
    display: flex;
    margin-bottom: map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('lg') {
      justify-content: center;
      margin-bottom: map-get($careers-layout-spacing, 'xxxl');
    }
  }

  &-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: map-get($font-sizes, 'xl');
    font-weight: map-get($font-weight, 'bolder');
    border-bottom: rem(2px) solid var(--color-brand-santas-gray);
    color: var(--color-brand-santas-gray);

    @include media-breakpoint-up('lg') {
      flex: unset;
      font-size: map-get($font-sizes, 'xxl');
      width: rem(240px);
    }

    label {
      cursor: pointer;
      padding: map-get($careers-layout-spacing, 'base');
      width: 100%;
    }

    &:hover {
      color: var(--color-brand-red-ribbon);
      border-color: var(--color-brand-red-ribbon);
    }
  }
}

.location-office__content--tabs {
  display: none;
}

@for $i from 1 through 4 {
  .location-office__tabs {
    [type="radio"]:nth-of-type(#{$i}):checked {
      ~ .location-office__content--tabs:nth-of-type(#{$i}) {
        display: flex;
      }

      ~ .location-office__tabs-list .location-office__tabs-item:nth-of-type(#{$i}) {
        color: var(--color-brand-red-ribbon);
        border-color: var(--color-brand-red-ribbon);
      }
    }
  }
}
