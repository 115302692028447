// Bootstrap toast override
.toast-notification {
  display: none;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin-bottom: map-get($careers-layout-spacing, 'xxxl');
  padding: map-get($careers-layout-spacing, 'base');
  border-radius: 0;
  box-shadow: none;
  z-index: map-get($zIndex, 'highest');

  &__content {
    display: flex;
    flex-direction: column;
    gap: map-get($careers-layout-spacing, 'xxs');
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 0;
    background-color: transparent;
    border-bottom: none;
    font-size: map-get($font-sizes, 'default');
    font-weight: map-get($font-weight, 'bolder');
  }

  &__body {
    padding: 0;
  }

  &__description {
    margin: 0;
    max-width: 230px;
  }

  &__close {
    color: $white;
    background-color: transparent;
    font-size: map-get($font-sizes, 'xxl');
    border: none;
    padding: 0;
    line-height: 1;
  }
}

// Bootstrap.native classes applied via JS

.toast-notification.show {
  display: flex;
  align-items: flex-start;
  gap: map-get($careers-layout-spacing, 'xs');
}

// Themes

.toast-notification.toast-notification--danger {
  color: $white;
  background-color: var(--color-brand-red-ribbon);

  .toast-notification__header {
    color: $white;
  }
}
