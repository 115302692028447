.image-cta__wrapper {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up('xl') {
    flex-direction: row;
    align-items: stretch;
  }
}

.image-cta__subtitle {
  font-size: map-get($font-sizes, 'sm');
  font-weight: map-get($font-weight, 'bolder');
  text-transform: uppercase;
  margin-bottom: map-get($careers-layout-spacing, 'lg');
}

.image-cta__heading {
  font-weight: map-get($font-weight, 'bolder');

  &--large {
    @include media-breakpoint-up('lg') {
      font-size: map-get($careers-heading-size-desktop, 'h1');
    }
  }
}

.image-cta__image {
  height: rem(360px);
  background: {
    position: center;
    size: cover;
    repeat: no-repeat;
  }

  @include media-breakpoint-up('md') {
    min-height: rem(550px);
  }

  @include media-breakpoint-up('xl') {
    flex-basis: 50%;
    flex-shrink: 0;
    min-height: rem(790px);
    height: auto;
  }
}

.image-cta__information {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: map-get($careers-layout-spacing, 'xxxl')
    map-get($careers-layout-spacing, 'lg');

  @include media-breakpoint-up('xl') {
    flex-basis: 50%;
    padding: map-get($careers-layout-spacing, 'xxxl');
  }
}

// Theme overrides
.content-section--valhalla {
  .image-cta__wrapper {
    color: $white;
  }
}
