.loading-spinner {
  display: none;
  justify-content: center;
  padding: map-get($careers-layout-spacing, 'xxxl') 0;

  &--display {
    display: flex;
  }
}

.loading-spinner__icon {
  width: map-get($careers-layout-spacing, 'xl');
  height: map-get($careers-layout-spacing, 'xl');
  border: 3px solid var(--color-brand-ghost);
  border-top-color: var(--color-brand-red-ribbon);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
