.list {
  padding: 0;
  list-style-type: none;

  &__item {
    margin-bottom: 1rem;

    font-size: map-get($font-sizes, 'xl');
    font-weight: map-get($font-weight, 'bolder');
  }
}

.list--unstyled {
  @include list-unstyled();

  margin-bottom: 0;
}

.list--link {
  .list__item-link {
    font-size: map-get($font-sizes, 'xl');
    color: map-get($color-brand, 'red-ribbon');
    text-transform: none;
  }
}
