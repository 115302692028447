.list-topic {
  @include media-breakpoint-up('lg') {
    @include make-row();
  }

  &__item {
    @include media-breakpoint-up('lg') {
      @include make-col-ready();
    }
  }

  &__item:not(:last-child) {
    margin-bottom: $grid-gutter-width/2;
  }

  &__icon {
    margin-bottom: 2rem;
  }

  &__title {
    margin-bottom: 1rem;

    font-size: map-get($font-sizes, 'lg');
  }

  &__title-link {
    color: map-get($color-gray, 'dark');

    &:hover {
      color: inherit;
    }
  }

  &__content {
    font-size: map-get($font-sizes, 'sm');
  }
}

// Modifiers

.list-topic--2-column {
  .list-topic__item {
    @include media-breakpoint-up('lg') {
      @include make-col(6);
    }
  }
}

.list-topic--3-column {
  .list-topic__item {
    @include media-breakpoint-up('lg') {
      @include make-col(4);
    }
  }
}

.list-topic--4-column {
  .list-topic__item {
    @include media-breakpoint-up('lg') {
      @include make-col(3);
    }
  }
}

.list-topic--6-column {
  .list-topic__item {
    @include media-breakpoint-up('lg') {
      @include make-col(2);
    }
  }
}

.list-topic--no-title {
  .list-topic__item {
    @include media-breakpoint-up('lg') {
      padding-left: 0;
      padding-right: 0;

      .list-topic__content {
        font-size: map-get($font-sizes, 'xl');
      }
    }
  }
}

.list-topic--centered {
  justify-content: center;

  .list-topic__item {
    text-align: center;
  }
}
