body {
  font-size: map-get($font-sizes, 'default');
  line-height: map-get($font-sizes, 'xxl');

  @include media-breakpoint-up('lg') {
    font-size: map-get($font-sizes, 'xl');
  line-height: map-get($careers-heading-leading-desktop, 'h5');
  }
}

// Headings

@each $selector, $size in $careers-heading-size-mobile {
  #{$selector} {
    font-size: $size;
    font-weight: map-get($font-weight, 'bolder');
    line-height: map-get($careers-heading-leading-mobile, $selector);
    
    @include media-breakpoint-up('lg') {
      font-size: map-get($careers-heading-size-desktop, $selector);
      line-height: map-get($careers-heading-leading-desktop, $selector);
    }
  }
}
