.hear-from {
  position: relative;

  figure,
  blockquote {
    margin: 0;
  }

  &__wrapper {
    text-align: center;

    @include media-breakpoint-up('md') {
      text-align: left;
    }

    h2 {
      font-weight: map-get($font-weight, 'bolder');
      margin-bottom: map-get($careers-layout-spacing, 'lg');
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('md') {
      flex-direction: row;
    }
  }

  &__quote {
    max-width: rem(780px);
  }

  &__profile-photo {
    @include employee-headshots(
      $width-mobile: rem(84px),
      $height-mobile: rem(84px),
      $width-desktop: rem(140px),
      $height-desktop: rem(140px)
    );
  }

  &__name {
    font-size: map-get($font-sizes, 'xxl');
    font-weight: map-get($font-weight, 'bolder');
    margin-bottom: map-get($careers-layout-spacing, 'base');
  }
}

.hear-from.hear-from--red-ribbon {
  color: $white;
}
