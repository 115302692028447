.logo-cta {
  position: relative;
  display: flex;
  align-items: center;
  gap: map-get($careers-layout-spacing, 'md');
  padding: map-get($careers-layout-spacing, 'md');
  color: $white;
  background-color: var(--color-brand-valhalla);
  overflow: hidden;

  @include media-breakpoint-up('lg') {
    padding: map-get($careers-layout-spacing, 'xl');
  }

  &__link {
    display: flex;
    align-items: center;
    gap: map-get($careers-layout-spacing, 'sm');

    > a {
      color: $white;
      font-size: map-get($font-sizes, 'default');
      font-weight: map-get($font-weight, 'bolder');
    }
  }

  h2.logo-cta__title {
    font-size: map-get($font-sizes, 'xl');
    margin-bottom: map-get($careers-layout-spacing, 'base');

    @include media-breakpoint-up('lg') {
      font-size: map-get($font-sizes, 'xxl');
    }
  }

  &__bg-graphic {
    position: absolute;
    top: 20px;
    right: -20px;
    width: calc(48px * 3.5);
    height: calc(80px * 3.5);
    opacity: 0.2;
  }
}
