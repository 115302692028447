.carousel-image {
  $max-height-mobile: rem(350px);
  $max-height-desktop: rem(415px);

  &__slide {
    overflow: hidden;
    max-height: $max-height-mobile;

    @include media-breakpoint-up('lg') {
      max-height: $max-height-desktop;
    }
  }

  &__item-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 100%;
    height: auto;
    max-height: $max-height-mobile;
    object-fit: cover;
    object-position: center;

    @include media-breakpoint-up('lg') {
      max-height: $max-height-desktop;
    }
  }

  &--no-desktop .carousel {
    @include media-breakpoint-up('lg') {
      display: none;
    }
  }

  // Desktop only image row

  &__desktop-row {
    display: none;
    max-height: $max-height-desktop;

    @include media-breakpoint-up('lg') {
      display: flex;
      gap: map-get($careers-layout-spacing, 'xxxs');
    }
  }

  &__desktop-row-item {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__desktop-row-image {
    width: 100%;
    height: 100%;
    max-height: $max-height-desktop;
    object-fit: cover;
    object-position: center;
  }

  // Component specific style overrides

  .carousel__list-indicator {
    padding-top: map-get($careers-layout-spacing, 'md');
    padding-bottom: 0;

    .carousel__indicator {
      width: map-get($careers-layout-spacing, 'base');
      height: map-get($careers-layout-spacing, 'base');
      margin-right: rem(6px);
      margin-left: rem(6px);
      border-radius: 0;
      border-top: none;
      border-bottom: none;
    }

    .carousel__indicator.active {
      background-color: var(--color-brand-red-ribbon);
    }
  }
}
