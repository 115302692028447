@mixin media-landscape() {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin media-portrait() {
  @media (orientation: portrait) {
    @content;
  }
}
