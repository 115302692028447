.open-position-list {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('lg') {
      gap: map-get($careers-layout-spacing, 'xxxl');
    }

    &__title {
      margin-bottom: 0;
    }
  }

  // Navigation

  &__nav {
    display: flex;
    justify-content: center;
  }

  // List and List Items

  &__list {
    width: 100%;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: map-get($careers-layout-spacing, 'sm');
    font-size: map-get($font-sizes, 'default');
    text-align: initial;
    width: 100%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: map-get($careers-layout-spacing, 'base');
    padding: map-get($careers-layout-spacing, 'base');
    border-bottom: 1px solid var(--color-brand-ghost);

    @include media-breakpoint-up('lg'){
      flex-direction: row;
      padding: map-get($careers-layout-spacing, 'md');
      gap: map-get($careers-layout-spacing, 'lg');
    }
  }

  &__item-title {
    font-weight: map-get($font-weight, 'bolder');
    flex-basis: 60%;

    > a {
      color: $base-font-color;

      @include multiple-line-underline();
    }
  }

  &__item-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-up('lg'){
      flex-grow: 1;
      justify-content: flex-start;
      gap: map-get($careers-layout-spacing, 'lg');
    }
  }

  &__item-department,
  &__item-location {
    @include media-breakpoint-up('lg'){
      flex-basis: 50%;
    }
  }

  // Column headings

  &__headings {
    display: none;
    width: 100%;
    padding: map-get($careers-layout-spacing, 'md');
    font-size: map-get($font-sizes, 'default');
    color: var(--color-brand-santas-gray);
    text-align: left;

    @include media-breakpoint-up('lg') {
      display: flex;
      align-items: center;
      gap: map-get($careers-layout-spacing, 'lg');
    }
  }

  &__headings-title {
    flex-basis: 60%;
  }

  &__headings-meta {
    display: flex;
    flex-grow: 1;
    gap: map-get($careers-layout-spacing, 'lg');
  }

  &__headings-meta-item {
    flex-basis: 50%;
  }

  .btn {
    margin-top: 0;
  }
}

.open-position-list__no-results {
  margin-bottom: map-get($careers-layout-spacing, 'md');

  &-icon {
    margin-bottom: map-get($careers-layout-spacing, 'md');
  }

  &-text {
    max-width: 65ch;
  }

  &-content {
    display: none;
  }
}

// City navbar style overrides

.open-position-list .navbar-scrollable__list {
  .nav-item {
    font-weight: map-get($font-weight, 'bolder');
  }
}
