.custom-file-upload {
  position: relative;
  width: 100%;

  input.custom-file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 2;

    &:focus ~ .custom-file-upload__content {
      outline: 0;
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
  }
  
  .custom-file-upload__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: map-get($careers-layout-spacing, 'xl')
      map-get($careers-layout-spacing, 'md');
    border: 1px solid var(--color-gray-primary);
  }
  
  .custom-file-upload__title {
    color: var(--color-brand-red-ribbon);
    font-weight: map-get($font-weight, 'bolder');
    margin-bottom: map-get($careers-layout-spacing, 'xxs');
  }
  
  .custom-file-upload__subtitle {
    color: var(--color-gray-semidark);
    margin-bottom: map-get($careers-layout-spacing, 'md');
  }

  .custom-file-upload__info {
    color: var(--color-gray-semidark);
    font-size: map-get($font-sizes, 'xs');
  }
  
  &.is-dragover .custom-file-upload__content {
    border-color: var(--color-brand-electric-violet);
    background-color: var(--color-gray-lighter);
  }
}

.was-validated .custom-file-upload__content {
  &:has(~ .invalid-feedback) {
    border-color: var(--color-brand-red-ribbon);
  }
}
