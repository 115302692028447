.hiring-process-steps {
  position: relative;

  .text-cta__wrapper {
    padding-bottom: map-get($careers-layout-spacing, 'xxxxl');
  }

  &__wrapper {
    padding-bottom: map-get($careers-layout-spacing, 'lg');
  }

  &__heading {
    margin-bottom: map-get($careers-layout-spacing, 'md');
    @include media-breakpoint-up('lg') {
      margin-bottom: map-get($careers-heading-size-desktop, 'h2');
    }
  }

  &__icon-mobile {
    position: absolute;
    right: 0;
    bottom: map-get($careers-layout-spacing, 'md');
    height: 220px;
    width: 220px;
    margin-right: map-get($careers-layout-spacing, 'lg') * -1;

    @include media-breakpoint-up('lg') {
      display: none;
    }
  }

  &__icon-desktop {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 460px;
    width: 460px;

    @include media-breakpoint-up('lg') {
      display: initial;
    }
  }
}
