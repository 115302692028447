.icon {
  display: inline-block;

  &--xs {
    width: 1rem;
    height: 1rem;
  }

  &--lg {
    width: rem(48px);
    height: rem(48px);
  }

  &--xl {
    width: 4rem;
    height: 4rem;
  }
}
