// Extending styles of the Bootstrap carousel that cannot be done via config variables

.carousel {
  &__control {
    display: none;

    top: 50%;
    transform: translateY(-50%);

    width: rem(42px);
    height: rem(57px);

    background: hsla(0, 0%, 0%, 0.2);

    @include media-breakpoint-up('lg') {
      display: flex;
    }

    &--prev {
      left: 20px;
    }

    &--next {
      right: 20px;
    }
  }

  &__list-indicator {
    position: relative;
    bottom: initial;
    left: initial;
    z-index: initial;

    padding: 30px 0 20px;
    margin: 0;

    .carousel__indicator {
      border-radius: 50%;
      background-color: map-get($color-gray, 'primary');
    }

    .active {
      background-color: $carousel-indicator-active-bg;
    }
  }
}
