@mixin employee-headshots(
  $width-mobile: rem(64px),
  $height-mobile: rem(64px),
  $width-desktop: rem(100px),
  $height-desktop: rem(100px)
) {
  width: $width-mobile;
  height: $height-mobile;
  border-radius: 50%;
  overflow: hidden;
  background-color: transparent;
  flex-shrink: 0;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include media-breakpoint-up('lg') {
    width: $width-desktop;
    height: $height-desktop;
  }
}
