.client-marquee {
  --item-width: 8.75rem; // 140px
  --item-gap: 4rem; // 64px

  &__row {
    overflow: hidden;
    position: relative;

    &:not(:last-child) {
      margin-bottom: map-get($careers-layout-spacing, 'lg');
    }
  }

  &__track {
    display: flex;
    gap: var(--item-gap);
    width: max-content;
    animation: marquee-scroll 60s linear infinite forwards;
    
    &:hover {
      animation-play-state: paused;
    }

    &--reverse {
      animation-direction: reverse;
      animation-duration: 70s;
    }
  }

  &__item {
    width: var(--item-width);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    .logo {
      max-height: rem(80px);
      max-width: var(--item-width);
      
      &:hover {
        filter: grayscale(0);
      }
    }
  }
}

// Accessibility - Display all logos 

@media (prefers-reduced-motion: reduce) {
  .client-marquee {
    &__row {
      padding: 0 map-get($careers-layout-spacing, 'lg');

      &:not(:last-child) {
        margin-bottom: map-get($careers-layout-spacing, 'xxxl');
      }
    }

    &__track {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: map-get($careers-layout-spacing, 'xxxl');
      width: 100%;
      animation: none;
    }

    &__item {
      flex-shrink: 0;
    }
  }

  .client-marquee__item--duplicate {
    display: none;
  }
}

@keyframes marquee-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-50% - var(--item-gap)/2));
  }
}
