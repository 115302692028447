.faq-accordion {
  position: relative;

  &__header {
    color: $white;
    text-align: center;
    margin-bottom: map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('lg') {
      margin-bottom: map-get($careers-layout-spacing, 'xxxl');
    }
  }

  &__question-answers {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: map-get($careers-layout-spacing, 'base');
    max-width: rem(860px);
    margin: 0 auto;
    z-index: map-get($zIndex, 'higher');
  }

  &__item {
    background-color: $white;
  }

  input.faq-accordion__checkbox {
    position: absolute;
    opacity: 0;
    z-index: map-get($zIndex, 'behind');
  }

  label.faq-accordion__question {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: map-get($careers-layout-spacing, 'base');
    padding: map-get($careers-layout-spacing, 'md');
    font-size: map-get($font-sizes, 'default');
    font-weight: map-get($font-weight, 'bolder');
    cursor: pointer;
    transition: padding-bottom $base-animation-duration ease-in-out;
    @include trigger-gpu-rendering();
  }

  &__question-text {
    max-width: map-get($grid-breakpoints, 'md');
  }

  &__caret {
    flex-shrink: 0;
    padding-top: map-get($careers-layout-spacing, 'xxs');
    transition: transform $base-animation-duration ease-in-out;
    @include trigger-gpu-rendering();
  }

  &__answer {
    color: var(--color-gray-semidark);
    max-height: 0;
    padding: 0 map-get($careers-layout-spacing, 'md');
    overflow: hidden;
    font-size: map-get($font-sizes, 'default');
    transition: max-height $base-animation-duration ease-in-out,
      padding $base-animation-duration ease-in-out;
    @include trigger-gpu-rendering();

    p {
      margin: 0;
    }
  }

  // When checkbox is checked, show the answer
  &__checkbox:checked ~ &__answer {
    max-height: rem(500px);
    padding: map-get($careers-layout-spacing, 'md');
    padding-top: 0;
  }

  // When checkbox is checked, rotate the caret icon to point upward
  &__checkbox:checked ~ &__question &__caret {
    transform: rotate(180deg);
  }

  // When checkbox is checked, increase bottom padding of the question
  &__checkbox:checked ~ &__question {
    padding-bottom: map-get($careers-layout-spacing, 'lg');
  }
}
