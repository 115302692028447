@import 'variables';

// Vendor
@import '../vendor/stylesheets/bootstrap/bootstrap';

// Application

@import 'functions/asset-url';
@import 'functions/image-url';
@import 'functions/sizing';

@import 'mixins/animation';
@import 'mixins/button';
@import 'mixins/client-logos';
@import 'mixins/grid';
@import 'mixins/responsive';
@import 'mixins/shape-sprite';
@import 'mixins/text';
@import 'mixins/underline';
@import 'mixins/image-overlay';
@import 'mixins/employee-headshots';
@import 'mixins/linear-gradient-background';
@import 'mixins/employee-headshots';
@import 'mixins/svg-color-filters';

@import 'base/reboot';
@import 'base/root';
@import 'base/fonts';
@import 'base/forms';
@import 'base/typography';

@import 'layouts/careers';

@import 'screens/careers/typography';
@import 'screens/careers/home';
@import 'screens/careers/open-position';

@import 'components/alert';
@import 'components/btn';
@import 'components/app-footer';
@import 'components/icon';
@import 'components/icon-hamburger';
@import 'components/link';
@import 'components/list';
@import 'components/list-social-platform';
@import 'components/logo';
@import 'components/loading-spinner';
@import 'components/logo-cta';
@import 'components/custom-file-upload';
@import 'components/toast-notification';

@import 'components/website/content-section';
@import 'components/website/list-topic';
@import 'components/website/carousel';
@import 'components/website/carousel-image';

@import 'components/careers/app-header';
@import 'components/careers/app-navigation-bar';
@import 'components/careers/app-footer';
@import 'components/careers/text-cta';
@import 'components/careers/statistics';
@import 'components/careers/hero';
@import 'components/careers/banner-heading';
@import 'components/careers/benefits-and-perks';
@import 'components/careers/hiring-process';
@import 'components/careers/open-position-list';
@import 'components/careers/open-position-explorer';
@import 'components/careers/open-position-detail';
@import 'components/careers/open-position-apply';
@import 'components/careers/navbar-scrollable';
@import 'components/careers/image-cta';
@import 'components/careers/bg-image-cta';
@import 'components/careers/impact-cta';
@import 'components/careers/client-cases';
@import 'components/careers/our-teams';
@import 'components/careers/testimonial';
@import 'components/careers/hear-from';
@import 'components/careers/information';
@import 'components/careers/subpage-heading';
@import 'components/careers/icon-decoration';
@import 'components/careers/simple-testimonial';
@import 'components/careers/client-marquee';
@import 'components/careers/hiring-process-infographic';
@import 'components/careers/faq-accordion';
@import 'components/careers/feature-list';
@import 'components/careers/content-gallery';
@import 'components/careers/location-office';

@import 'screens/careers/hiring-process';
