body.careers-hiring-process {
  .careers-hiring-process__content-sections {
    display: flex;
    flex-direction: column;
    gap: map-get($careers-layout-spacing, 'xxxl');
    padding: map-get($careers-layout-spacing, 'xxxl') 0;

    @include media-breakpoint-up('lg') {
      padding: map-get($careers-layout-spacing, 'xxxl') * 2 0;
    }
  }
}
