.app-navigation-bar {
  flex-direction: column;
  align-items: flex-start;
  padding: map-get($careers-layout-spacing, 'md') 0;

  @include media-breakpoint-up('xl') {
    flex-direction: row;
    align-items: center;
    padding: map-get($careers-layout-spacing, 'lg') 0;
  }

  &::before {
    position: absolute;
    top: 0;
    left: -24px;
    content: '';
    z-index: map-get($zIndex, 'behind');
    width: 100vw;
    height: rem(80px);
    background-color: $white;
    box-shadow: $careers-navbar-box-shadow;
    transition: height $base-animation-duration ease-in-out $base-animation-duration/2;

    @include trigger-gpu-rendering();

    @include media-breakpoint-up('xl') {
      left: -64px;
      height: rem(112px);
    }
  }
}

.app-navigation-bar__btn-toggle-menu {
  position: absolute;
  right: 0;

  @include media-breakpoint-up('xl') {
    display: none;
  }

  .icon-hamburger,
  .icon-hamburger:before,
  .icon-hamburger:after {
    background-color: var(--color-gray-semidark);

    transition: {
      duration: $base-animation-duration;
      timing-function: swing;
    }
  }
}

.app-navigation-bar__navigation {
  display: flex;
  visibility: hidden;
  transform: translateX(-100vw);
  width: 100%;
  height: 0;

  transition: height $base-animation-duration ease-in-out $base-animation-duration/2;
  @include trigger-gpu-rendering();

  @include media-breakpoint-up('xl') {
    display: flex;
    visibility: visible;
    flex-direction: row;
    width: auto;
    height: auto;
    transform: translateX(0);
  }

  > * {
    opacity: 0;
    transition: opacity $base-animation-duration/2 ease-in-out;
    @include trigger-gpu-rendering();

    @include media-breakpoint-up('md') {
      opacity: 1;
    }
  }

  .btn {
    width: 100%;
  }
}

// Menu animation

// Control the colored menu height
.app-navigation-bar {
  &.app-navigation-bar--menu-open {
    height: 100%;

    &::before {
      transition-delay: 0s;
      height: inherit;
    }
  }
}

.app-navigation-bar--menu-open {
  .app-navigation-bar__btn-toggle-menu .icon-hamburger {
    background-color: transparent;

    &::before {
      background-color: var(--color-brand-red-ribbon);
      transform: translateY(9px) rotate(45deg);
    }

    &::after {
      background-color: var(--color-brand-red-ribbon);
      transform: translateY(-9px) rotate(-45deg);
    }
  }

  .app-navigation-bar__navigation {
    // Reset element to be default state in screen
    visibility: visible;
    transform: translateX(0);

    // Scale height by animating
    height: 100%;
    transition-delay: 0s;

    // Fade-in some elements inside navigation box
    > * {
      opacity: 1;
      transition-delay: $base-animation-duration * 1.25;
    }
  }
}
