.our-teams {
  &__heading {
    padding-top: map-get($careers-layout-spacing, 'xxxxl');
    padding-bottom: map-get($careers-layout-spacing, 'xxxl');

    h2 {
      margin-bottom: 0;

      @include media-breakpoint-up('lg') {
        font-size: map-get($careers-heading-size-desktop, 'h1');
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: map-get($careers-layout-spacing, 'xxxs');

    @include media-breakpoint-up('lg') {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__department {
    min-height: rem(360px);
    background: {
      position: center;
      size: cover;
      repeat: no-repeat;
    }
    color: $white;

    padding: map-get($careers-layout-spacing, 'lg');
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    @include image-overlay;

    @include media-breakpoint-up('xl') {
      min-height: rem(640px);
    }

    &::after {
      display: none;
    }

    &:hover {
      color: $white;

      .our-teams__department-cta {
        color: var(--color-brand-red-ribbon);
      }
    }

    &-heading {
      font-size: map-get($careers-heading-size-mobile, 'h2');
      line-height: 1em;
      margin-bottom: map-get($careers-layout-spacing, 'xs');

      @include media-breakpoint-up('lg') {
        font-size: map-get($careers-heading-size-desktop, 'h2');
      }
    }
  }

  &__department-cta {
    display: flex;
    gap: map-get($careers-layout-spacing, 'xs');
    align-items: center;
    color: $white;
    font-size: map-get($font-sizes, 'xl');
    font-weight: map-get($font-weight, 'bolder');
  }
}
