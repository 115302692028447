.testimonial-section {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up('lg') {
    flex-direction: row;
  }

  &__description,
  &__quote {
    flex-basis: 50%;
    padding: map-get($careers-layout-spacing, 'xxxxl')
      map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('lg') {
      padding-right: map-get($careers-layout-spacing, 'xl');
      padding-left: map-get($careers-layout-spacing, 'xl');
    }
  }

  &__description {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: map-get($careers-layout-spacing, 'base');
    color: $white;
    background-color: var(--color-brand-red-ribbon);

    h2 {
      margin-bottom: 0;
    }
  }

  &__quote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: map-get($careers-layout-spacing, 'lg');

    figure {
      margin-bottom: 0;
    }

    blockquote {
      margin-bottom: map-get($careers-layout-spacing, 'lg');
    }

    figcaption {
      margin-bottom: 0;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    gap: map-get($careers-layout-spacing, 'base');
  }

  &__author-photo {
    @include employee-headshots(
      rem(64px),
      rem(64px),
      rem(100px),
      rem(100px)
    );
  }

  &__author-name {
    font-size: map-get($careers-heading-size-mobile, 'h5');
    font-weight: map-get($font-weight, 'bolder');
    margin-bottom: map-get($careers-layout-spacing, 'xxs');

    @include media-breakpoint-up('lg') {
      font-size: map-get($font-sizes, 'xl');
    }
  }

  &__author-position {
    font-size: map-get($font-sizes, 'default');
    color: var(--color-gray-semidark);
  }
}

// Testimonial Alt

.testimonial-section.testimonial-section--alt {
  .testimonial-section__quote {
    padding: map-get($careers-layout-spacing, 'xxxl')
      map-get($careers-layout-spacing, 'md');

    @include media-breakpoint-up('lg') {
      padding: map-get($careers-layout-spacing, 'xxxl');
    }
  }

  .testimonial-section__quote-figure {
    display: flex;
    flex-direction: column;
    gap: map-get($careers-layout-spacing, 'lg');
    margin-bottom: 0;
  }

  .testimonial-section__quote-text {
    margin-bottom: 0;
  }

  .testimonial-section__author-name {
    font-size: map-get($heading-sizes, 'h2');
    margin-bottom: map-get($careers-layout-spacing, 'xs');
  }

  .testimonial-section__author-position {
    font-size: map-get($font-sizes, 'xl');

    @include media-breakpoint-up('lg') {
      font-size: map-get($font-sizes, 'xxl');
    }
  }

  .testimonial-section__photo {
    min-height: rem(450px);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    @include media-breakpoint-up('lg') {
      flex-basis: 50%;
    }

    @include media-breakpoint-up('xxl') {
      min-height: rem(650px);
    }
  }
}
