.bg-image-cta__grid {
  display: grid;
  grid-template-columns: 1fr;

  @include media-breakpoint-up('md') {
    grid-template-columns: repeat(2, 1fr);
  }
}

.bg-image-cta__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: rem(360px);
  padding: map-get($careers-layout-spacing, 'xxxxl')
    map-get($careers-layout-spacing, 'lg');

  color: $white;
  background: {
    position: center;
    size: cover;
    repeat: no-repeat;
  }

  @include image-overlay;

  @include media-breakpoint-up('xl') {
    min-height: rem(640px);
    padding: map-get($careers-layout-spacing, 'xxxl') * 3
      map-get($careers-layout-spacing, 'xxxl');
  }

  .btn {
    width: fit-content;
    margin-top: map-get($careers-layout-spacing, 'md');
  }
}

.bg-image-cta__content {
  flex-grow: 1;
  width: 100%;
}
