.logo {
  display: inline-block;
  max-width: rem(140px);
  max-height: rem(80px);
  white-space: initial;

  &__icon {
    width: inherit;
    height: inherit;
  }

  &--grayscale {
    filter: grayscale(1);

    &:hover {
      filter: grayscale(0);
    }
  }
}

// Remove underline from logo links

a.logo {
  &:hover::after {
    display: none;
  }
}
