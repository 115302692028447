@import "utilities/screenreaders";

// Re-enable if needed

//@import "utilities/align";
//@import "utilities/background";
//@import "utilities/borders";
//@import "utilities/clearfix";
@import "utilities/display";
//@import "utilities/embed";
//@import "utilities/flex";
//@import "utilities/float";
//@import "utilities/interactions";
//@import "utilities/overflow";
//@import "utilities/position";
//@import "utilities/shadows";
//@import "utilities/sizing";
//@import "utilities/spacing";
//@import "utilities/stretched-link";
//@import "utilities/text";
// @import "utilities/visibility";
