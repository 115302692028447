.statistics {
  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: map-get($careers-layout-spacing, 'xxxl');
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include media-breakpoint-up('md') {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
  }

  &__label {
    margin: 0;
    color: map-get($color-brand, 'red-ribbon');
    font-size: map-get($careers-heading-size-mobile, 'h1');
    font-weight: map-get($font-weight, 'bolder');
    line-height: map-get($careers-heading-size-desktop, 'h1');

    @include media-breakpoint-up('md') {
      font-size: map-get($careers-heading-size-desktop, 'h1');
    }
  }

  &__content {
    color: map-get($color-gray, 'semidark');
    font-size: map-get($font-sizes, 'xl');

    @include media-breakpoint-up('md') {
      font-size: map-get($font-sizes, 'xxl');
    }
  }
}
