.app-footer {
  background-color: var(--color-brand-valhalla);
  align-items: center;

  @include media-breakpoint-up('md') {
    flex-wrap: nowrap;
  }

  &__copyright {
    margin-top: 0;
  }

  &__social {
    align-items: normal;
    height: unset;

    @include media-breakpoint-up('md') {
      flex-basis: unset;
      margin-top: 0;
    }
  }
}

.list-social-platform {
  flex-wrap: wrap;
  justify-content: center;
  gap: map-get($careers-layout-spacing, 'md');

  &__link {
    width: map-get($careers-layout-spacing, 'md');
    height: map-get($careers-layout-spacing, 'md');

    &:hover {
      svg {
        @include svg-color-filter-red-ribbon;
      }
    }
  }
}
