.content-section {
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;

  @include media-breakpoint-up('lg') {
    padding-top: rem(100px);
    padding-bottom: rem(100px);
  }

  h2 {
    line-height: 1.25;

    @include media-breakpoint-up('lg') {
      margin-bottom: 2rem;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  &__text {
    @include media-breakpoint-up('lg') {
      margin-right: calc(100% - 650px);
    }

    @include media-breakpoint-up('xxl') {
      margin-right: calc(100% - 750px);
    }
  }

  &__text-link {
    @include multiple-line-underline();

    color: hsl(0, 0%, 100%);
    background-image: linear-gradient(to bottom, currentColor 0%, currentColor 100%);

    &:hover {
      color: currentColor;
    }
  }

  .btn {
    width: 100%;
    margin-top: rem(40px);

    @include media-breakpoint-up('lg') {
      width: auto;
      margin-top: rem(60px);
    }
  }
}

// Background modifiers

.content-section--with-background {
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-up('lg') {
    background-size: initial;
  }
}

.content-section--with-translucent-background {
  background: {
    position: calc(100% + 160px) - 70px;
    repeat: no-repeat;
  }
}

// Theme modifiers

.content-section--full-width {
  .content-section__text {
    @include media-breakpoint-up('lg') {
      margin-right: 0;
    }
  }
}

.content-section--colored {
  @include make-content-row();

  padding-right: $grid-gutter-width/2;
  padding-left: $grid-gutter-width/2;

  color: hsl(0, 0%, 100%);

  @include media-breakpoint-up('lg') {
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }
}

.content-section--grid {
  .content-section__text {
    display: grid;
    grid-template-rows: repeat(2, auto);

    @include media-breakpoint-up('lg') {
      grid-template-columns: auto calc(100% - 650px);

      margin-right: 0;
    }

    @include media-breakpoint-up('xxl') {
      grid-template-columns: auto calc(100% - 750px);

      margin-right: 0;
    }
  }

  .content-section__text-image {
    display: none;
    width: 100%;
    height: 100%;

    text-align: center;

    padding-top: 1rem;
    padding-bottom: 2rem;
    margin-bottom: 0;

    img {
      max-width: initial;
      max-height: 100%;
    }

    @include media-breakpoint-up('lg') {
      display: block;
      grid-row-start: 1;
      grid-row-end: 3;

      grid-column-start: 2;

      justify-self: center;
      align-self: center;

      width: rem(222px);
      height: auto;

      img {
        max-width: 100%;
        max-height: initial;
      }
    }

    @include media-breakpoint-up('xl') {
      width: rem(371px);
      height: rem(201px);
    }

    @include media-breakpoint-up('xxl') {
      width: rem(526px);
      height: rem(286px);
    }
  }

  figure.content-section__text-image {
    min-width: 100%;
    min-height: 100%;

    @include media-breakpoint-up('lg') {
      min-width: initial;
      min-height: initial;
    }
  }
}

.content-section--grid-column {
  @include media-breakpoint-up('lg') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.content-section--centered {
  text-align: center;
}

.content-section--white {
  background-color: $white;
}

.content-section--gray {
  background-color: map-get($color-gray, 'lighter');
}

@each $color, $value in $color-brand {
  .content-section--#{$color} {
    background-color: var( --color-brand-#{$color});
  }
}

// Spacing modifiers

.content-section--no-padding {
  padding: 0;
}

.content-section--thin {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include media-breakpoint-up('lg') {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
