.subpage-heading {
  padding: map-get($careers-layout-spacing, 'xxxl')
    map-get($careers-layout-spacing, 'lg');

  @include media-breakpoint-up('xl') {
    padding-right: map-get($careers-layout-spacing, 'xxxl');
    padding-left: map-get($careers-layout-spacing, 'xxxl');
  }

  h1 {
    // font-size: map-get($heading-sizes, 'h4');
    // font-weight: map-get($font-weight, 'bolder');
    margin-bottom: 0;
  }

  &__back {
    display: inline-block;
  }

  &__back > a {
    display: flex;
    align-items: center;
    gap: map-get($careers-layout-spacing, 'xs');
    font-size: map-get($font-sizes, 'default');
    color: $base-font-color;
    margin-bottom: map-get($careers-layout-spacing, 'md');

    .icon {
      transform: rotate(180deg);
    }
  }
}
