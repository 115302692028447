// Heading size overrides for careers homepage

.careers {
  .feature-list__title,
  .hear-from h2,
  .benefits-and-perks .benefits-and-perks__title,
  .open-position-list .open-position-list__title {
    @include media-breakpoint-up('lg') {
      font-size: map-get($careers-heading-size-desktop, 'h1');
    }
  }

  .open-position-list {
    padding: map-get($careers-layout-spacing, 'xxxxl') 0;
  }
}
