.alert {
  padding: rem(20px);

  color: hsl(0, 0%, 100%);
  text-align: center;

  &--info {
    background: map-get($color-alert, 'info');
  }
}
