// Apply a nicer and animated button
@mixin animated-button($accent-color) {
  overflow: hidden;

  &:before {
    @include transition(transform $base-animation-duration, opacity $base-animation-duration);

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: map-get($zIndex, 'behind');

    width: 1rem;
    height: 1rem;

    opacity: 0;
    background: darken($accent-color, 10%);
    border-radius: 50%;
    transform: scale(1) translate(-50%, -50%);
    will-change: opacity;
  }

  &:hover:before {
    transform: scale(45);
    opacity: 1;

    @include media-breakpoint-up('lg') {
      transform: scale(18);
    }
  }

  &:hover,
  &:focus {
    border-color: darken($accent-color, 10%);
  }

  &:focus {
    background: darken($accent-color, 10%);
    border-color: darken($accent-color, 10%);
    outline: none
  }
}
