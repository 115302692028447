@mixin responsive-client-logos() {
  @each $client-name, $width in $client-logo-width-vw {
    .list-customer__logo--#{$client-name} {
      @media (max-width: 435px) {
        width: $width;
      }

      @media (min-width: 436px) and (max-width: 991px) {
        width: $width + 1vw;
      }

      @include media-breakpoint-up('lg') {
        width: map-get($client-logo-width-px, $client-name) * 0.85;
      }

      @include media-breakpoint-up('xl') {
        width: map-get($client-logo-width-px, $client-name) * 0.9;
      }

      @include media-breakpoint-up('xxl') {
        width: map-get($client-logo-width-px, $client-name);
      }
    }
  }

}

@mixin responsive-customer-section() {
  @include media-breakpoint-down('sm') {
    gap: rem(24px 8px);
    margin-top: rem(24px);
    margin-bottom: rem(32px);
  }

  @media (min-width: 596px) and (max-width: 829px) {
    gap: rem(24px 16px)
  }

  @media (min-width: 830px) and (max-width: 991px) {
    gap: rem(24px 24px)
  }

  @include media-breakpoint-down('md') {
    margin-top: rem(24px);
    margin-bottom: rem(39px);
  }

  @include media-breakpoint-up('lg') {
    gap: rem(0 8px);
    margin-top: rem(24px);
    margin-bottom: rem(39px);
  }

  @include media-breakpoint-up('xl') {
    gap: rem(0 20px);
  }
}

@mixin grayscale-logo() {
  filter: grayscale(1);
}
