.client-cases {
  &__heading {
    padding-top: map-get($careers-layout-spacing, 'xxxl');
    padding-bottom: map-get($careers-layout-spacing, 'xxxl');

    @include media-breakpoint-up('lg') {
      padding-top: map-get($careers-layout-spacing, 'xxxxl');
    }

    h2 {
      margin-bottom: 0;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;

    @include media-breakpoint-up('lg') {
      grid-template-columns: repeat(3, 1fr);
      gap: map-get($careers-layout-spacing, 'xxs') / 2;
    }
  }

  &__grid-item {
    @include media-breakpoint-up('lg') {
      display: flex;
      flex-direction: column;

      $span-66: span 2; // 66%
      $span-33: span 1; // 33%

      // Repeating pattern every 4 items
      &:nth-child(4n + 1) {
        grid-column: $span-66;
      }

      &:nth-child(4n + 2) {
        grid-column: $span-33;
      }

      &:nth-child(4n + 3) {
        grid-column: $span-33;
      }

      &:nth-child(4n + 4) {
        grid-column: $span-66;
      }

      // Last child when odd number of items - span full width
      &:last-child:nth-child(odd) {
        grid-column: 1 / -1;
      }
    }
  }

  &__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    padding: map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('lg') {
      height: rem(500px);
      padding: map-get($careers-layout-spacing, 'xl');
    }

    @include media-breakpoint-up('xxl') {
      height: rem(600px);
    }

    > img {
      position: relative;
      z-index: map-get($zIndex, 'high');
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__copy {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    gap: map-get($careers-layout-spacing, 'base');
    padding: map-get($careers-layout-spacing, 'lg');

    @include media-breakpoint-up('md') {
      padding-top: map-get($careers-layout-spacing, 'lg');
      padding-right: map-get($careers-layout-spacing, 'xxxl');
      padding-bottom: map-get($careers-layout-spacing, 'xxl');
      padding-left: map-get($careers-layout-spacing, 'xxxl');
    }
  }

  &__copy-title {
    font-size: map-get($font-sizes, 'xxl');
    margin-bottom: map-get($careers-layout-spacing, 'xs');
  }

  &__copy-excerpt {
    font-size: map-get($font-sizes, 'xl');
  }

  &__copy-link {
    font-weight: map-get($font-weight, 'bolder');
  }

  &__icon {
    position: absolute;
    left: 0;
    bottom: 0;
    width: rem(300px);
    height: rem(300px);
  }
}

.client-cases__grid-item:last-child {
  .client-cases__copy {
    padding-bottom: map-get($careers-layout-spacing, 'xxxxl');
  }
}

// Client specific branding

@each $client, $color in $client-cases-bg-brand-colors {
  .client-cases__image--#{$client} {
    background-color: $color;

    // Special cases

    @if $client == 'cbtl' {
      justify-content: flex-end;
      padding-right: 0;
    }

    @if $client == 'the-1' {
      align-items: flex-end;
      padding-bottom: 0;
    }
  }
}
